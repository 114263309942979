import { checkpaypassword, payment, calculate, orderCreate, balanceConfig } from "@/api/order/payment"
import { addressList, saveAddress, setDefault, deleteAddress, addressInfo } from "@/api/member/member"
import { getArea } from "@/api/address"
import { mapGetters } from "vuex"

export default {
	name: "payment",
	components: {},
	data: () => {
		var checkMobile = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入手机号"))
			} else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(value)) {
				callback(new Error("手机号格式错误"))
			} else {
				callback()
			}
		}

		return {
			dialogVisible: false,
			memberAddress: [], //收货地址列表
			addressId: 0, //收货地址
			addressForm: {
				id: 0,
				name: "",
				mobile: "",
				telephone: "",
				province_id: "",
				city_id: "",
				district_id: "",
				community_id: "",
				address: "",
				full_address: "",
				is_default: "",
				longitude: "",
				latitude: ""
			},
			pickerValueArray: {},
			cityArr: {},
			districtArr: {},
			addressRules: {
				name: [
					{
						required: true,
						message: "请输入收货人",
						trigger: "blur"
					},
					{
						min: 1,
						max: 20,
						message: "长度在 1 到 20 个字符",
						trigger: "blur"
					}
				],
				mobile: [
					{
						required: true,
						validator: checkMobile,
						trigger: "change"
					}
				],
				province: [
					{
						required: true,
						message: "请选择省",
						trigger: "change"
					}
				],
				city: [
					{
						required: true,
						message: "请选择市",
						trigger: "change"
					}
				],
				district: [
					{
						required: true,
						message: "请选择区/县",
						trigger: "change"
					}
				],
				address: [
					{
						required: true,
						message: "请输入详细地址",
						trigger: "change"
					}
				]
			},
			isSend: false,
			orderCreateData: {
				is_balance: 0,
				is_point: 1,
				pay_password: "",

				// 发票
				is_invoice: 0, // 是否需要发票 0 无发票  1 有发票
				invoice_type: '1', // 发票类型  1 纸质 2 电子
				receipt_type: '1', // 是否需要发票 0 无发票  1 普票 2 专票
				invoice_title_type: 1, // 抬头类型  1 个人 2 企业
				is_tax_invoice: 0, // 是否需要增值税专用发票  0 不需要 1 需要
				invoice_title: "", // 发票抬头
				taxpayer_number: "", // 纳税人识别号
				invoice_content: "", // 发票内容
				invoice_full_address: "", // 发票邮寄地址
				invoice_email: "", //发票邮箱
				member_address: {
					mobile: ""
				},
				is_open_card: 0,
				member_card_unit: ""
			},
			orderPaymentData: {
				shop_goods_list: {
					site_name: "",
					express_type: [],
					coupon_list: [],
					invoice: {
						invoice_content_array: []
					}
				},
				member_account: {
					balance: 0,
					is_pay_password: 0
				},
				delivery: {
					delivery_type: ""
				},
				member_address: {
					mobile: ""
				},
				local_config: {
					info: {
						start_time: 0,
						end_time: 0,
						time_week: []
					}
				}
			},
			dialogCoupon: false,
			siteCoupon: {
				site_id: 0,
				data: []
			},
			siteDelivery: {
				site_id: 0,
				data: []
			},
			dialogStore: false,
			promotionInfo: false,
			storeList: {},
			sitePromotion: [],
			isSub: false,
			dialogpay: false,
			password: "",
			fullscreenLoading: true,
			deliveryTime: false,
			timeTip: "选择配送时间",
			time: null,
			addressShow: false,
			couponRadio: 0,
			storeRadio: 0,
			dialogPlatcoupon: false,
			platformCouponRadio: 0,
			// 门店信息
			storeInfo: {
				storeList: [], //门店列表
				currStore: {} //当前选择门店
			},
			// 自提地址
			member_address: {
				mobile: ""
			},
			// 当前时间
			timeInfo: {
				week: 0,
				start_time: 0,
				end_time: 0,
				showTime: false,
				showTimeBar: false
			},
			balance_show: 1,
			addresNextType: true,
			Exchangeoffer: "", //换购计算
			SpecialOffer: "", //特价计算
			Totaloffer: "", //总计优惠
			freeGoodsMoney: "",//换购商品价格
			invoiceRadio: [{
				value:'1',name:'无',check:false
			},{
				value:'2',name:'普票',check:false
			},{
				value:'3',name:'专票',check:false
			}],
			Totaloffera: ''
		}
	},
	computed: {
		...mapGetters(["orderCreateGoodsData", "defaultGoodsImage", "city"]),
		calculationOfPrice() {
			return (item) => {
				let { num, goods_limit, price, discount_price } = item
				let withinLimit = Math.min(num, goods_limit)
				let overLimit = Math.max(num - goods_limit, 0)
				let totalPrice = withinLimit * discount_price + overLimit * price
				// 先判断是否超出限购数量 goods_limit
				return totalPrice.toFixed(2)
			}
		}
	},
	mounted() {},
	created() {
		this.getMemberAddress()
		this.getOrderPaymentData()
		this.getBalanceConfig()
	},

	filters: {
		/**
		 * 金额格式化输出
		 * @param {Object} money
		 */
		moneyFormat(money) {
			return parseFloat(money).toFixed(2)
		},
		/**
		 * 店铺优惠摘取
		 */
		promotion(data) {
			let promotion = ""
			if (data) {
				Object.keys(data).forEach((key) => {
					promotion += data[key].content + "　"
				})
			}
			return promotion
		}
	},
	methods: {
		//获取余额支付配置
		getBalanceConfig() {
			balanceConfig()
				.then((res) => {
					const { code, message, data } = res

					if (code >= 0) {
						this.balance_show = data.balance_show
					}
				})
				.catch((err) => {
					const { code, message, data } = err
					// this.$message.error(message)
				})
		},
		//获取收货地址
		getMemberAddress() {
			addressList({
				page_size: 0
			})
				.then((res) => {
					const { code, message, data } = res
					if (data && data.list) {
						let that = this
						this.memberAddress = data.list
						data.list.forEach(function (e) {
							if (e.is_default == 1) {
								that.addressId = e.id
							}
						})
					}
				})
				.catch((err) => {
					const { code, message, data } = err
					this.$message.error(message)
				})
		},

		//设置客户收货地址
		setMemberAddress(params) {
			this.addressId = params
			setDefault({
				id: params
			})
				.then((res) => {
					const { code, message, data } = res
					this.orderCalculate()
				})
				.catch((err) => {
					const { code, message, data } = err
					this.$message.error(message)
				})
		},

		//删除客户收货地址
		deleteMemberAddress(params) {
			deleteAddress({
				id: params
			})
				.then((res) => {
					const { code, message, data } = res
					if (data) {
						this.$message({
							message: message,
							type: "success"
						})
						this.getMemberAddress()
					} else {
						this.$message({
							message: message,
							type: "warning"
						})
					}
				})
				.catch((err) => {
					this.$message.error(err.message)
				})
		},

		//打开添加收货地址弹出层
		addAddressShow() {
			this.dialogVisible = true
			this.addressForm.id = 0
			this.addressForm.name = ""
			this.addressForm.mobile = ""
			this.addressForm.telephone = ""
			this.addressForm.province_id = ""
			this.addressForm.city_id = ""
			this.addressForm.district_id = ""
			this.addressForm.community_id = ""
			this.addressForm.address = ""
			this.addressForm.full_address = ""
			this.addressForm.is_default = ""
			this.addressForm.longitude = ""
			this.addressForm.latitude = ""
			// this.$nextTick(() => {
			// 	this.$refs.form.resetFields();
			// });
			this.cityArr = {}
			this.districtArr = {}
			this.getAddress(0)
		},

		//获取地址
		getAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//加载省
					pid = 0
					break
				case 1:
					//加载市
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					this.addressForm.city_id = ""
					this.addressForm.district_id = ""
					break
				case 2:
					//加载区县
					pid = this.addressForm.city_id
					that.districtArr = {}
					this.addressForm.district_id = ""
					break
			}

			getArea({
				pid: pid
			})
				.then((res) => {
					const { code, message, data } = res
					if (data) {
						switch (type) {
							case 0:
								that.pickerValueArray = data
								break
							case 1:
								//加载市
								that.cityArr = data
								break
							case 2:
								//加载区县
								that.districtArr = data
								break
						}
					}
					if (data.length == 0) {
						this.addresNextType = false
					}
				})
				.catch((err) => {
					const { code, message, data } = err
					this.$message.error(message)
				})
		},

		//编辑地址 初始化
		initAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//加载省
					pid = 0
					break
				case 1:
					//加载市
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					break
				case 2:
					//加载区县
					pid = this.addressForm.city_id
					that.districtArr = {}
					break
			}

			getArea({
				pid: pid
			})
				.then((res) => {
					const { code, message, data } = res
					if (data) {
						switch (type) {
							case 0:
								that.pickerValueArray = data
								break
							case 1:
								//加载市
								that.cityArr = data
								break
							case 2:
								//加载区县
								that.districtArr = data
								break
						}
					}
					if (data.length == 0) {
						this.addresNextType = false
					}
				})
				.catch((err) => {
					const { code, message, data } = err
					this.$message.error(message)
				})
		},

		//新增/编辑收货地址
		addmemberAddress(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.isSend) {
						return false
					}

					if (!this.addressForm.id) {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" + this.$refs.district.selectedLabel
						let data = {
							name: this.addressForm.name,
							mobile: this.addressForm.mobile,
							telephone: this.addressForm.telephone,
							province_id: this.addressForm.province_id,
							city_id: this.addressForm.city_id,
							district_id: this.addressForm.district_id,
							community_id: "",
							address: this.addressForm.address,
							full_address: this.addressForm.full_address,
							longitude: this.addressForm.longitude,
							latitude: this.addressForm.latitude,
							is_default: this.addressForm.is_default,
							url: "add"
						}

						if (!data.province_id || data.province_id <= 0) {
							this.$message({
								message: "请正确选择省",
								type: "warning"
							})
							return false
						}
						if (!data.city_id || data.city_id <= 0) {
							this.$message({
								message: "请正确选择市",
								type: "warning"
							})
							return false
						}
						if ((!data.district_id || data.district_id <= 0) && this.addresNextType == true) {
							this.$message({
								message: "请正确选择区/县",
								type: "warning"
							})
							return false
						}
						this.isSend = true

						saveAddress(data)
							.then((res) => {
								const { code, message, data } = res
								if (data) {
									this.setMemberAddress(data)

									this.$message({
										message: message,
										type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$message({
										message: message,
										type: "warning"
									})
								}
								this.isSend = false
							})
							.catch((err) => {
								const { code, message, data } = err
								this.$message.error(message)
							})
					} else {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" + this.$refs.district.selectedLabel
						let data = this.addressForm
						if (!data.province_id) {
							this.$message({
								message: "请选择省",
								type: "warning"
							})
							return false
						}
						if (!data.city_id) {
							this.$message({
								message: "请选择市",
								type: "warning"
							})
							return false
						}
						if (!data.district_id) {
							this.$message({
								message: "请选择区/县",
								type: "warning"
							})
							return false
						}
						this.isSend = true
						this.setMemberAddress(data.id)
						data.url = "edit"
						saveAddress(data)
							.then((res) => {
								const { code, message, data } = res
								if (data) {
									this.$message({
										message: message,
										type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$message({
										message: message,
										type: "warning"
									})
								}
								this.isSend = false
							})
							.catch((err) => {
								const { code, message, data } = err
								this.$message.error(message)
							})
					}
				} else {
					return false
				}
			})
		},

		//编辑收货地址
		editAddress(id) {
			addressInfo({
				id: id
			})
				.then((res) => {
					const { code, message, data } = res
					this.addressForm = {
						id: data.id,
						name: data.name,
						mobile: data.mobile,
						telephone: data.telephone,
						province_id: data.province_id,
						city_id: "",
						district_id: "",
						community_id: "",
						address: data.address,
						full_address: data.full_address,
						is_default: data.is_default,
						longitude: data.longitude,
						latitude: data.latitude
					}
					this.initAddress(0)
					this.initAddress(1)
					this.addressForm.city_id = data.city_id
					this.initAddress(2)
					this.addressForm.district_id = data.district_id

					this.dialogVisible = true
				})
				.catch((err) => {
					const { code, message, data } = err
					this.$message.error(message)
				})
		},

		/**
		 * 获取订单初始化数据
		 */
		getOrderPaymentData() {
			this.orderCreateData = this.orderCreateGoodsData
			if (!this.orderCreateData) {
				this.$message({
					message: "未获取到创建订单所需数据！", //提示的信息
					type: "warning",
					offset: 225,
					duration: 3000,
					onClose: () => {
						this.$router.go(-1)
						return false
					}
				})
				return
			}

			this.orderCreateData.web_city = this.city ? this.city.id : 0
			payment(this.orderCreateData)
				.then((res) => {
					const { code, message, data } = res
					if (code >= 0) {
						this.orderPaymentData = data
						const address = res.data?.member_address;
						if (!(address && address.full_address && address.name && address.mobile && address.province_id && address.city_id && address.district_id)){
							this.$confirm(
								'收货地址不完善，请先完善收货地址！',
								{
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning',
								}
							).then(() =>{
								this.$router.push({
									name: "address_edit",
									query:{
										id:address.id
									}
								})
							})
						}

						this.orderPaymentData.shop_goods_list.goods_list.forEach((item) => {
							if (item.get_goods_free != null) {
								this.Exchangeoffer = ((item.num / item.get_goods_free.count) * item.discount_price).toFixed(2)
							}
							if (item.is_discount > 0) {
								if (item.num > item.goods_limit) {
									this.SpecialOffer = Number(this.SpecialOffer) + Number((item.goods_limit * (item.price - item.discount_price)).toFixed(2)) //特价总计
								} else {
									this.SpecialOffer = Number(this.SpecialOffer) + Number((item.num * (item.price - item.discount_price)).toFixed(2)) //特价总计
								}
							}
							// this.SpecialOffer = Number(this.SpecialOffer.toFixed(2));
							var Totalofferadd = parseInt(this.Exchangeoffer)
							var Totalofferaddt = parseInt(this.SpecialOffer)
							this.Totaloffera = (Totalofferadd + Totalofferaddt).toFixed(2)
							this.$forceUpdate()
						})
						// 控制发票隐藏
						const invoices = this.orderPaymentData.invoices;
						const invoice_status_keys = ['invoice_status', 'invoice_status_two', 'invoice_status_three']
						this.invoiceRadio = this.invoiceRadio.filter((v, i) => {
							return invoices[invoice_status_keys[i]] == 1
						});
						this.handlePaymentData()
					} else {
						this.$message({
							message: "未获取到创建订单所需数据！", //提示的信息
							type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
				})
				.catch((err) => {
					const { code, message, data } = err
					// this.$message.error(message)
				})
		},
		/**
		 * 处理结算订单数据
		 */
		handlePaymentData() {
			this.orderCreateData.delivery = {}
			this.orderCreateData.coupon = {}
			this.orderCreateData.buyer_message = ""
			this.orderCreateData.is_balance = 0
			this.orderCreateData.pay_password = ""
			this.orderCreateData.is_point = 1

			this.orderCreateData.is_invoice = 0 // 是否需要发票 0 无发票  1 有发票
			this.orderCreateData.invoice_type = '1' // 发票类型  1 纸质 2 电子
			this.orderCreateData.receipt_type = '1'
			this.orderCreateData.invoice_title_type = 1 // 发票抬头类型 1 个人 2企业
			this.orderCreateData.is_tax_invoice = 0 // 是否需要增值税专用发票  0 不需要 1 需要
			this.orderCreateData.invoice_title = ""

			var data = this.orderPaymentData
			if (data.recommend_member_card) {
				this.orderCreateData.member_card_unit = Object.keys(data.recommend_member_card.charge_rule)[0]
			}
			// 店铺的配送
			if (data.shop_goods_list.express_type.length > 1) {
				this.orderCreateData.delivery_type = "express"
				this.orderCreateData.delivery_type_name = "物流配送"
			}
			// 店铺优惠券
			if (data.shop_goods_list.coupon_list != undefined && data.shop_goods_list.coupon_list[0] != undefined) {
				var coupon_list = data.shop_goods_list.coupon_list
				this.orderCreateData.coupon.coupon_id = coupon_list[0].coupon_id
				this.orderCreateData.coupon.coupon_money = coupon_list[0].money
			}

			// 满减送
			if (data.shop_goods_list.promotion != undefined && data.shop_goods_list.promotion.manjian != undefined) {
				this.manjian = data.shop_goods_list.promotion.manjian
			}

			if (this.orderPaymentData.is_virtual)
				this.orderCreateData.member_address = {
					mobile: ""
				}

			if (this.orderPaymentData.shop_goods_list.invoice) {
				var invoice_content_array = this.orderPaymentData.shop_goods_list.invoice.invoice_content_array
				if (invoice_content_array.length) this.orderCreateData.invoice_content = invoice_content_array[0]
			}

			if (this.orderPaymentData.is_virtual == 0) {
				// 是否显示时间选择
				if (this.orderPaymentData.shop_goods_list.local_config.info && this.orderPaymentData.shop_goods_list.local_config.info.time_is_open == 1) {
					this.timeInfo.showTimeBar = true
					// 当日是否支持配送
					if (this.orderPaymentData.shop_goods_list.local_config.info.time_week.length == 0 || this.orderPaymentData.shop_goods_list.local_config.info.time_week.length == 7 || this.orderPaymentData.shop_goods_list.local_config.info.time_week.indexOf(this.timeInfo.week) > -1) {
						this.canLocalDelicery = true
					} else {
						this.canLocalDelicery = false
					}

					if (this.orderPaymentData.shop_goods_list.local_config.info.time_type == 0) {
						this.deliveryWeek = "全天"
					} else if (this.orderPaymentData.shop_goods_list.local_config.info.time_week.length > 0) {
						if (this.orderPaymentData.shop_goods_list.local_config.info.time_week.length == 7) {
							this.deliveryWeek = "全天"
						} else {
							this.timeInfo.showTime = true
							// 判断配送时间是连续还是间隔
							var timeWeek = this.orderPaymentData.shop_goods_list.local_config.info.time_week
							var is_interval = false // 是否间隔
							for (var i = 0; i < timeWeek.length; i++) {
								if (i + 1 < timeWeek.length) {
									var difference = timeWeek[i + 1] - timeWeek[i]
									if (difference > 1) {
										is_interval = true
										break
									}
								}
							}

							var weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
							if (is_interval) {
								var temp = []
								for (var i = 0; i < timeWeek.length; i++) {
									temp.push(weeks[timeWeek[i]])
								}
								this.deliveryWeek = temp.join("、")
							} else {
								this.deliveryWeek = weeks[timeWeek[0]] + "至" + weeks[timeWeek[timeWeek.length - 1]]
							}
						}
					} else {
						this.deliveryWeek = "店铺未设置配送时间"
					}

					// 将timepicker选中当前时间
					var h = new Date().getHours().toString()
					var m = new Date().getMinutes().toString()
					if (h.length == 1) {
						h = "0" + h
					}
					if (m.length == 1) {
						m = "0" + m
					}

					this.orderCreateData.buyer_ask_delivery_time = h + ":" + m

					// picker组件时间起始
					let start_time = this.orderPaymentData.shop_goods_list.local_config.info.start_time
					this.timeInfo.start_time = this.getTimeStr(start_time)

					let end_time = this.orderPaymentData.shop_goods_list.local_config.info.end_time
					this.timeInfo.end_time = this.getTimeStr(end_time)

					var current_time = new Date(this.$util.timeStampTurnTime(this.orderPaymentData.timestamp))
					var hour = current_time.getHours()
					var minute = current_time.getMinutes()

					var start_hour = parseInt(this.timeInfo.start_time.split(":")[0])
					var start_minute = parseInt(this.timeInfo.start_time.split(":")[1])

					var end_hour = parseInt(this.timeInfo.end_time.split(":")[0])
					var end_minute = parseInt(this.timeInfo.end_time.split(":")[1])

					// 检测当天是否能够配送，然后判断送达时间。不在配送时间当日不能下单，例：配送时间是周一到周五，那么周末不能下单，周一到周五可以下单
					if (this.canLocalDelicery) {
						// 判断是否全天
						if (!(start_hour == end_hour && start_minute == end_minute)) {
							// 当前时间早于配送时间，送达时间：开始时间~结束时间
							if (hour < start_hour || (hour == start_hour && minute < start_minute)) {
								this.orderCreateData.buyer_ask_delivery_time = (start_hour.toString().length == 1 ? "0" + start_hour : start_hour) + ":" + (start_minute.toString().length == 1 ? "0" + start_minute : start_minute)
							}

							// if (((hour > start_hour && hour < end_hour) || (hour == start_hour && minute > start_minute) || (hour ==
							// 		start_hour && minute >= start_minute && hour < end_hour))) {
							// }

							// 当前时间晚于配送时间，送达时间隐藏，不能下单
							if (hour > end_hour || (hour == end_hour && minute > end_minute)) {
								this.canLocalDelicery = false
							}
						}
					}
				} else {
					this.deliveryWeek = "店铺未开启配送时间"
				}
			}

			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.orderPaymentData.shop_goods_list.goods_list.forEach((v) => {
				if (v.sku_spec_format) {
					v.sku_spec_format = JSON.parse(v.sku_spec_format)
				} else {
					v.sku_spec_format = []
				}
			})
			this.orderCalculate()
		},
		// 转化时间字符串
		getTimeStr(val) {
			var h = parseInt(val / 3600).toString()
			var m = parseInt((val % 3600) / 60).toString()
			if (m.length == 1) {
				m = "0" + m
			}
			if (h.length == 1) {
				h = "0" + h
			}
			return h + ":" + m
		},
		clickType(type) {
			this.orderCreateData.receipt_type = type
			// this.orderCalculate()
			this.$forceUpdate()
		},
		clickTitleType(type) {
			this.orderCreateData.invoice_title_type = type
			this.orderCalculate()
			this.$forceUpdate()
		},
		// 选择发票内容
		changeInvoiceContent(invoice_content) {
			this.orderCreateData.invoice_content = invoice_content
			this.$forceUpdate()
		},

		// 切换发票开关
		changeIsInvoice() {
			if (this.orderCreateData.is_invoice == 0) {
				this.orderCreateData.is_invoice = 1
			} else {
				this.orderCreateData.is_invoice = 0
			}
			this.orderCalculate()
			this.$forceUpdate()
		},

		// 发票验证
		invoiceVerify() {
			if (!this.orderCreateData.invoice_title) {
				this.$message({
					message: "请填写发票抬头",
					type: "warning"
				})
				return false
			}
			if (!this.orderCreateData.taxpayer_number && this.orderCreateData.invoice_title_type == 2) {
				this.$message({
					message: "请填写纳税人识别号",
					type: "warning"
				})
				return false
			}
			if (this.orderCreateData.invoice_type == 1 && !this.orderCreateData.invoice_full_address) {
				this.$message({
					message: "请填写发票邮寄地址",
					type: "warning"
				})
				return false
			}
			if (this.orderCreateData.invoice_type == 2 && !this.orderCreateData.invoice_email) {
				this.$message({
					message: "请填写邮箱",
					type: "warning"
				})
				return false
			}
			if (this.orderCreateData.invoice_type == 2) {
				var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
				if (!reg.test(this.orderCreateData.invoice_email)) {
					this.$message({
						message: "请填写正确的邮箱",
						type: "warning"
					})
					return false
				}
			}
			if (!this.orderCreateData.invoice_content) {
				this.$message({
					message: "请选择发票内容",
					type: "warning"
				})
				return false
			}
			return true
		},

		/**
		 * 订单计算
		 */
		orderCalculate() {
			this.fullscreenLoading = true
			var data = this.$util.deepClone(this.orderCreateData)
			data.delivery = JSON.stringify(data.delivery)
			data.coupon = JSON.stringify(data.coupon)
			if (this.orderPaymentData.delivery_type == "store") {
				data.member_address = JSON.stringify(this.member_address)
			} else {
				data.member_address = JSON.stringify(data.member_address)
			}
			calculate(data)
				.then((res) => {
					const { code, message, data } = res
					if (code >= 0) {
						this.orderPaymentData.member_address = res.data.member_address
						this.orderPaymentData.delivery_money = res.data.delivery_money
						this.orderPaymentData.coupon_money = res.data.coupon_money
						this.orderPaymentData.invoice_money = res.data.invoice_money
						this.orderPaymentData.invoice_delivery_money = res.data.shop_goods_list.invoice_delivery_money
						this.orderPaymentData.promotion_money = res.data.promotion_money
						this.orderPaymentData.order_money = res.data.order_money
						this.orderPaymentData.balance_money = res.data.balance_money
						this.orderPaymentData.pay_money = res.data.pay_money
						this.orderPaymentData.goods_money = res.data.goods_money
						this.orderPaymentData.point_money = res.data.point_money
						this.orderPaymentData.member_card_money = res.data.member_card_money
						this.orderPaymentData.shop_goods_list.promotion = res.data.shop_goods_list.promotion
						this.orderPaymentData.shop_goods_list.coupon_list = res.data.shop_goods_list.coupon_list
						this.orderPaymentData.shop_goods_list.goods_list = res.data.shop_goods_list.goods_list
						this.orderPaymentData.shop_goods_list.max_usable_point = res.data.shop_goods_list.max_usable_point
						// 针对优惠券特殊处理
						// if (this.orderPaymentData.shop_goods_list.coupon_list.length == 0) {
						// 	this.orderCreateData.coupon = {};
						// } else if (!this.orderCreateData.coupon.coupon_id) {
						// 	var coupon_list = res.data.shop_goods_list.coupon_list;
						// 	this.orderCreateData.coupon.coupon_id = coupon_list[0].coupon_id;
						// 	this.orderCreateData.coupon.coupon_money = coupon_list[0].money;
						// 	// this.orderCalculate();
						// }

						this.orderPaymentData.shop_goods_list.goods_list.forEach((v) => {
							if (v.sku_spec_format) {
								v.sku_spec_format = JSON.parse(v.sku_spec_format)
							} else {
								v.sku_spec_format = []
							}
						})
					} else {
						this.$message({
							message: message, //提示的信息
							type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
					this.fullscreenLoading = false
				})
				.catch((err) => {
					const { code, message, data } = err
					this.$message.error(message)
					this.fullscreenLoading = false
				})
		},

		/**
		 * 选择配送方式
		 */
		/**
		 * 选择配送方式
		 */
		selectDeliveryType(data, siteId, deliveryData) {
			this.tempData = {
				delivery: this.$util.deepClone(this.orderPaymentData.delivery)
			}

			this.siteDelivery.site_id = siteId
			this.siteDelivery.data = deliveryData

			this.orderCreateData.delivery_type = data.name
			this.orderCreateData.delivery_type_name = data.title
			if (data.name == "store") {
				// 如果是门店配送
				// let row = '';
				let that = this
				data.store_list.forEach(function (e, i) {
					data.store_list[i]["store_address"] = e.full_address + e.address
				})

				this.dialogStore = true
				this.storeList = data.store_list
				this.orderPaymentData.delivery.delivery_type = "store"
				this.orderPaymentData.delivery.delivery_type_name = "门店自提"
				Object.assign(this.orderPaymentData, this.orderCreateData)
				if (this.orderPaymentData.delivery.store_id) {
					this.orderCalculate()
				} else {
					this.orderPaymentData.delivery.store_id = 0
				}
			} else if (data.name == "local") {
				this.deliveryTime = true
				this.time = this.orderCreateData.delivery[siteId].buyer_ask_delivery_time
			} else {
				this.orderPaymentData.delivery.delivery_type = "express"
				this.orderPaymentData.delivery.delivery_type_name = "物流配送"
				Object.assign(this.orderPaymentData, this.orderCreateData)
				this.orderCalculate()
			}
			this.$forceUpdate()
		},

		/**
		 * 设置选择自提点
		 * @param {Object} item
		 */
		setStore(row) {
			this.$refs.singleTable.setCurrentRow(row)
		},

		/**
		 * 选择自提点
		 * @param {Object} item
		 */
		selectStore(item) {
			if (!item) return
			let store_id = item.store_id
			this.dialogStore = false
			this.orderCreateData.delivery.store_id = store_id
			this.orderCreateData.delivery.delivery_type = this.orderCreateData.delivery_type
			this.orderCreateData.delivery.store_name = item.store_name
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.storeRadio = item
			this.orderCalculate()
			this.$forceUpdate()
		},

		/**
		 * 显示优惠券信息
		 * @param {Object} siteId
		 * @param {Object} couponData
		 */
		openPlatformCoupon() {
			let row = 0
			let that = this
			this.dialogPlatcoupon = true
			this.orderPaymentData.shop_goods_list.coupon_list.forEach(function (e, i) {
				if (e.coupon_id == that.orderPaymentData.coupon.coupon_id) {
					row = e
				}
			})

			setTimeout(function () {
				that.setPlatformCurrent(row)
				that.platformCouponRadio = row
			}, 50)
		},

		/**
		 * 取消选择优惠券
		 * @param {Object} item
		 */
		setPlatformCurrent(row) {
			this.$refs.platformCouponTable.setCurrentRow(row)
			if (row == undefined) {
				this.orderCalculate()
			}
		},
		/**
		 * 确认选择优惠券
		 * @param {Object} item
		 */
		savePlatformCoupon() {
			this.dialogPlatcoupon = false
			this.orderCalculate()
		},

		/**
		 * 选择优惠券
		 * @param {Object} item
		 */
		selectPlatformCoupon(item) {
			if (this.orderCreateData.coupon.coupon_id != item.coupon_id) {
				this.orderCreateData.coupon.coupon_id = item.coupon_id
				this.orderCreateData.coupon.coupon_money = item.money
				this.platformCouponRadio = item
			} else {
				this.orderCreateData.coupon.coupon_id = 0
				this.orderCreateData.coupon.coupon_money = "0.00"
				this.platformCouponRadio = ""
			}
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.$forceUpdate()
		},

		/**
		 * 显示店铺优惠券信息
		 * @param {Object} siteId
		 * @param {Object} couponData
		 */
		// openSiteCoupon(siteId, couponData) {
		// 	this.tempData = {
		// 		coupon: this.$util.deepClone(this.orderPaymentData.coupon)
		// 	}
		// 	let row = 0
		// 	let that = this
		// 	this.siteCoupon.site_id = siteId
		// 	couponData.forEach(function(e, i) {
		// 		if (e.at_least > 0) {
		// 			couponData[i]["use"] = "满" + e.at_least + "可用"
		// 		} else {
		// 			couponData[i]["use"] = "任意金额可用"
		// 		}
		// 		couponData[i]["time"] = timeStampTurnTime(e.end_time)

		// 		if (e.coupon_id == that.orderCreateData.coupon[that.siteCoupon.site_id].coupon_id) {
		// 			row = e
		// 		}
		// 	})
		// 	this.siteCoupon.data = couponData
		// 	this.dialogCoupon = true

		// 	setTimeout(function() {
		// 		that.setCurrent(row)
		// 		that.couponRadio = row;
		// 	}, 50)
		// },
		/**
		 * 选择优惠券
		 * @param {Object} item
		 */
		selectCoupon(item) {
			if (!item) {
				this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id = 0
				this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_money = "0.00"
				this.couponRadio = ""
			} else {
				if (this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id != item.coupon_id) {
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id = item.coupon_id
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_money = item.money
					this.couponRadio = item
				} else {
					this.couponRadio = ""
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_id = 0
					this.orderCreateData.coupon[this.siteCoupon.site_id].coupon_money = "0.00"
				}
			}
			Object.assign(this.orderPaymentData, this.orderCreateData)

			this.$forceUpdate()
		},

		/**
		 * 取消选择优惠券
		 * @param {Object} item
		 */
		setCurrent(row) {
			this.$refs.couponTable.setCurrentRow(row)
			if (row == undefined) {
				this.orderCalculate()
			}
		},
		/**
		 * 确认选择优惠券
		 * @param {Object} item
		 */
		saveCoupon() {
			this.dialogCoupon = false
			this.orderCalculate()
		},

		/**
		 * 显示店铺优惠信息
		 * @param {Object} data
		 */
		openSitePromotion(data) {
			this.sitePromotion = data
			if (this.promotionInfo) {
				this.promotionInfo = false
			} else {
				this.promotionInfo = true
			}
		},

		/**
		 * 是否使用余额
		 */
		useBalance(type) {
			if (this.orderCreateData.is_balance) this.orderCreateData.is_balance = 0
			else this.orderCreateData.is_balance = 1
			this.orderCalculate()
			this.$forceUpdate()
		},

		// 使用积分
		usePoint(type) {
			if (this.orderCreateData.is_point) this.orderCreateData.is_point = 0
			else this.orderCreateData.is_point = 1
			this.orderCalculate()
			this.$forceUpdate()
		},

		orderCreate() {
			if (this.verify()) {
				if (this.isSub) return
				if (this.orderCreateData.is_invoice == 1) {
					if (this.invoiceVerify() === false) return
				}

				this.isSub = true

				var loading = this.$loading({
					lock: true,
					text: "订单提交中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})
				var data = this.$util.deepClone(this.orderCreateData)
				data.delivery = JSON.stringify(data.delivery)
				data.coupon = JSON.stringify(data.coupon)
				if (this.orderCreateData.delivery.delivery_type == "store") {
					data.member_address = JSON.stringify(this.member_address)
				} else {
					data.member_address = JSON.stringify(data.member_address)
				}
				// 选择的换购商品
				let freeMap = {}
				let freeGoodsList = this.orderPaymentData.shop_goods_list.goods_list.filter((ele) => ele.get_goods_free !== null && ele.num >= ele.get_goods_free.count)
				freeGoodsList.forEach((item) => {
					// 选择了的换购商品的id
					freeMap[item.goods_id] = item.get_goods_free.getFreeGoods.filter((freeGoods) => freeGoods.checked).map((freeGoods_id) => freeGoods_id.goods_id)
				})
				data.freeGoodsMap = JSON.stringify(freeMap)
				orderCreate(data)
					.then((res) => {
						const { code, message, data } = res
						loading.close()
						if (code >= 0) {
							this.$store.dispatch("order/removeOrderCreateData", "")
							if (this.orderPaymentData.pay_money == 0) {
								this.$router.push({
									path: "/result",
									query: {
										code: data
									}
								})
							} else {
								this.$router.push({
									path: "/pay",
									query: {
										code: data
									}
								})
							}
						} else {
							this.isSub = false
							this.$message({
								message: message,
								type: "warning"
							})
						}
					})
					.catch((err) => {
						loading.close()
						this.isSub = false
						const { code, message, data } = err
						this.$message.error(message)
					})
			}
		},
		/**
		 * 订单验证
		 */
		verify() {
			if (this.orderPaymentData.is_virtual == 1) {
				if (!this.orderCreateData.member_address.mobile.length) {
					this.$message({
						message: "请输入您的手机号码",
						type: "warning"
					})
					return false
				}
				var reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
				if (!reg.test(this.orderCreateData.member_address.mobile)) {
					this.$message({
						message: "请输入正确的手机号码",
						type: "warning"
					})
					return false
				}
			}

			if (this.orderPaymentData.is_virtual == 0) {
				if (!this.orderPaymentData.member_address) {
					this.$message({
						message: "请先选择您的收货地址",
						type: "warning"
					})

					return false
				}

				let deliveryVerify = true

				for (let key in this.orderCreateData.delivery) {
					if (JSON.stringify(this.orderCreateData.delivery[key]) == "{}") {
						deliveryVerify = false
						this.$message({
							message: '店铺"' + this.orderPaymentData.shop_goods_list[key].site_name + '"未设置配送方式',
							type: "warning"
						})

						break
					}
					if (this.orderCreateData.delivery[key].delivery_type == "store" && this.orderCreateData.store_id == 0) {
						deliveryVerify = false
						this.$message({
							message: '店铺"' + this.orderPaymentData.shop_goods_list[key].site_name + '"没有可提货的门店,请选择其他配送方式',
							type: "warning"
						})

						break
					}
				}
				if (!deliveryVerify) return false
			}
			if (this.orderPaymentData.delivery_type == "store") {
				if (JSON.stringify(this.orderPaymentData.delivery) == "{}") {
					this.$message({
						message: "请选择自提门店",
						type: "warning"
					})
					return false
				}
			}
			// if (this.orderCreateData.is_balance == 1 && this.orderCreateData.pay_password == "") {

			// 	this.dialogpay = true
			// 	return false
			// }
			return true
		},
		/**
		 * 支付密码输入
		 */
		input() {
			if (this.password.length == 6) {
				var loading = this.$loading({
					lock: true,
					text: "支付中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})

				checkpaypassword({
					pay_password: this.password
				})
					.then((res) => {
						const { code, message, data } = res
						loading.close()
						if (code >= 0) {
							this.orderCreateData.pay_password = this.password
							this.orderCreate()
							this.dialogpay = false
						} else {
							this.$message({
								message: message,
								type: "warning"
							})
						}
					})
					.catch((err) => {
						loading.close()
						const { code, message, data } = err
						this.$message.error(message)
					})
			}
		},

		textarea() {
			this.$forceUpdate()
		},
		bindTimeChange(time) {
			this.time = time
		},
		setDeliveryTime(site_id) {
			this.deliveryTime = false
			this.orderCreateData.delivery[site_id].buyer_ask_delivery_time = this.time
		},
		imageError(index) {
			this.orderPaymentData.shop_goods_list.goods_list[index].sku_image = this.defaultGoodsImage
		},
		setPayPassword() {
			this.$router.pushToTab("/member/security")
		},
		qucoudan() {
			this.$router.pushToTab("/index/index")
		}
	}
}
